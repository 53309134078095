(function() {
	class LazyImage extends HTMLElement {
		constructor() {
			super();
			this.src    = this.getAttribute('src');
			this.alt    = this.getAttribute('alt');
			this.width  = this.getAttribute('width');
			this.height = this.getAttribute('height');

			const self = this;
			const observer = new IntersectionObserver((entries) => {
				$(entries).each(function(index, entry) {
					if(entry.isIntersecting) {
						const img = document.createElement('img');

						img.src = self.src || '';
						img.alt = self.alt || '';
						img.classList.add('img-fluid');

						entry.target.appendChild(img);

						img.addEventListener('load', function() {
							$(entry.target).addClass('loaded');
						});

						observer.unobserve(entry.target);
					}
				});
			}, {
				rootMargin: '200px 200px 200px 200px'
			});

			observer.observe(this);
		}
	}

	customElements.define('lazy-image', LazyImage);
})();
