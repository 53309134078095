var btnmbl = document.querySelector('.btn-mbl');

btnmbl.addEventListener('click', function(evento) {
	var target = this.getAttribute('data-target');
	document.querySelector(target).classList.toggle('active');
	this.classList.toggle('active');
	$('.bg-menu').fadeIn(400);
});

$('.bg-menu').click(function(){
	$('.main-navigation').removeClass('active');
	$('.btn-mbl').removeClass('active');
	$(this).fadeOut(400);
});
