var progress = $('.progress');

setTimeout(function() {
	progress.attr('style', '--progress: 10;');
}, 200);

var bar = $('.progress .bar');

window.addEventListener('load', function() {

	setTimeout(function() {

		progress.attr('style', '--progress: 100;');

		$('body').addClass('ready');

		$('#loading-page').fadeOut(600, function() {
			$(this).remove();
		});

	}, 200);

});
