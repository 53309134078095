(function() {
	dismissAlerta();

	function dismissAlerta () {
		var btns = $('[data-dismiss="alerta"]');

		btns.each(function() {
			if(!$(this).data('dismiss.alerta')) {

				$(this).click(function() {

					$(this).blur();

					$(this).parents('.alerta').fadeOut(600, function() {
						$(this).remove();
					});

				});

				$(this).data('dismiss.alerta', true);
			}
		});

		requestAnimationFrame(dismissAlerta);
	}
})();
