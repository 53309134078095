var clicarAside = document.querySelector('.btn-aside');

if(clicarAside) {

	clicarAside.addEventListener('click', function(event) {

		var target = this.getAttribute('data-target'); // #menu

		document.querySelector(target).classList.toggle('active');

		this.classList.toggle('active');

		$('.bg-aside').fadeIn(400);
	});
}


$('.bg-aside').click(function(){
	$('#asideFiltro').removeClass('active');
	$('.btn-aside').removeClass('active');

	$(this).fadeOut(400);
});
