const EventBus = (function() {
	let EventMap = {};
	const $on = (e,fn) => {
		EventMap[e] = EventMap[e] || [];

		EventMap[e].push(fn);
	};
	const $emit = (e, data) => {
		if(EventMap[e]) {
			EventMap[e].forEach(fn => fn(data));
		}
	};

	return {
		$emit,
		$on
	}
})();

window.$on   = EventBus.$on;
window.$emit = EventBus.$emit;