function verificaTopoPosition(){
	var windowPosition = $(window).scrollTop();
	var altura = $('.topo').outerHeight();

	if(windowPosition >= altura){
		$('.topo').css('min-height', $('.topo').outerHeight());
		$('.topo').addClass('fx')

	}else{
		$('.topo').removeAttr('style');
		$('.topo').removeClass('fx');
	}
};

verificaTopoPosition();

$(window).scroll(function(){

	verificaTopoPosition();

});