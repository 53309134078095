var CustomSelect = (function() {
	var selects 	= $('select');

	selects.each(function() {
		var select 	= $(this);
		var value	= select.val();

		select.wrapAll('<div class="select-custom dropdown"></div>');

		var container 	= select.parent('.select-custom');
		var options 	= select.find('option');

		container.append(`<div class="form-control output" data-toggle="dropdown">${options[0].innerHTML}</div>`);
		container.append(`<ul class="dropdown-menu"></ul>`);

		var output	= container.find('.output');
		var drop 	= container.find('.dropdown-menu');

		options.each(function(index, el) {
			var option 	= $(this);
			var val 	= option.attr('value');
			var text 	= option.html();

			var newOption = document.createElement('li');

			$(newOption).data('value', val).html(text);

			if(el.hasAttribute('disabled')) {
				$(newOption).addClass('disabled')
			}else {

				$(newOption).click(function() {
					$(this).siblings('li').removeClass('selected');
					$(this).addClass('selected');

					select.val(val).trigger('change');
					output.html(text);
				});
			}

			drop.append(newOption);
		});
	});
})();
