(function() {
	Object.defineProperty(window, 'midiaSize', {
		get() {
			var size = 'xs';
			var w = window.innerWidth;

			if(w < 500) {
				size = 'xs';
			}

			if(w >= 500) {
				size = 'sm';
			}

			if(w >= 768) {
				size = 'md';
			}

			if(w >= 992) {
				size = 'lg';
			}

			if(w >= 1200) {
				size = 'xl';
			}

			return size;
		}
	})

})();

const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function basePath () {
	let p = '';
	let l = window.location;

	if(l.protocol == 'http:' || l.protocol == 'https:') {
		p = `${l.protocol}//${l.host}/`;

		if(l.pathname == '/novo' || l.pathname == '/novo/'){
			p += 'novo/';
		}
	}

	return p;
}

window.addEventListener('load', function(){

	$(window).trigger('scroll');
});
