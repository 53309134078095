$("[data-toggle='img-foto']").click(function(event){
	var target = $(this).data("target");
	var href = $(this).attr("href");
	var alt = $(this).attr('title');

	event.preventDefault();
	$(target).find("img").attr("src", href);
	$(target).find("img").attr("alt", alt);
	$(target).find("a").attr("href", href);
	$(target).find("a").attr("title", alt);
})
