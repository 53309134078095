

window.addEventListener('load', function() {
	const dropGrid       = document.querySelector('.drop__grid');
	const categorias     = document.querySelectorAll('.drop__grid__list li');
	const categoriasLink = document.querySelectorAll('.drop__grid__list li a');
	const getEvent       = 'get.category.card';
	const setEvent       = 'set.category.card';
	const frame			 = 1000/60;
	let loaded           = 0;


	if(!dropGrid) return false;

	const cardArea         = document.querySelector('.card__area');
	const cardImage        = document.querySelector('.card__area img');
	const cardTitle        = document.querySelector('.card__dropdown__title');
	const cardPrice        = document.querySelector('.card__dropdown__price');
	const cardDescription  = document.querySelector('.card__area .content__editable');
	const cardBtnSaibaMais = document.querySelector('.card__area .btn-card-dropdown');
	const cardBtnCategoria = document.querySelector('.card__area .btn-orange');

	// Registrando o evento para pegar a categoria
	categoriasLink.forEach($link => {
		const parent = $link.parentNode;
		const categoryId = parent.dataset.categoryId;

		$link.addEventListener('click', e => {
			e.preventDefault();

			window.$emit(getEvent, {
				id: categoryId
			});
		}, false);
	});

	categorias.forEach($li => {
		const categoryId = $li.dataset.categoryId;

		window.$on(getEvent, e => {
			const action = e.id === categoryId ? 'add' : 'remove';

			$li.classList[action]('active');
		})
	});

	// Geristrando a animação de loading
	window.$on(getEvent, function() {


		if(midiaSize == 'xs' || midiaSize == 'sm') {
			setTimeout(() => {
				let top = $('.card__area').offset().top;

				window.scrollTo({
					top: top - $('.topo').outerHeight(),
					behavior: 'smooth'
				});
			}, frame*2)
		}
		cardArea.classList.add('loading');
	});

	// Setando os itens retornados pelo servidor
	window.$on('set.category.card', e => {
		const { url, img, descricao, card } = e;

		setBG(img);
		if(cardBtnCategoria) cardBtnCategoria.setAttribute('href', url);
		if(cardDescription) cardDescription.innerHTML = descricao;

		if(cardTitle) cardTitle.innerHTML = card.title;
		if(cardImage) {
			cardImage.src       = card.img;
			cardImage.alt       = card.title;
		}

		if(cardPrice) cardPrice.innerHTML = card.preco;
		if(cardBtnSaibaMais) cardBtnSaibaMais.setAttribute('href', card.url);

	});

	cardImage.addEventListener('load', function() {
		isLoaded();
	});

	function isLoaded() {
		loaded++;
		if(loaded == 2) {
			loaded = 0;

			setTimeout(() => {
				cardArea.classList.remove('loading');
			}, (1000/60))
		}
	}

	function setBG(bg){
		let img = new Image();
		img.src = bg;

		img.addEventListener('load', () => {
			isLoaded();
			cardArea.style.setProperty('background-image', `url(${JSON.stringify(bg)})`);
		})

	}
});
